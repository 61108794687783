<template>
  <div class="wrapper">
    <div class="top">
      <div class="content">
        <img class="logo" src="../../assets/contact_aimore.png" />
        
        <div class="title">
          <h2>{{this.$t("contact.title1")}}</h2>
          <h2>{{this.$t("contact.title2")}}</h2>
        </div>

        <p class="subtitle">{{this.$t("contact.subtitle")}}</p>
      </div>
    </div>

    <el-form :model="form" :rules="rules" ref="ruleForm" label-position="top">
      <div class="form-title">
        <h2>{{this.$t("contact.subtitle1")}}</h2>
        <h2>{{this.$t("contact.subtitle2")}}</h2>
      </div>
      <el-form-item :label="$t('contact.labelcompany')" prop="company">
        <el-input v-model="form.company" maxlength="64" :placeholder="$t('contact.holdercompany')" clearable></el-input>
      </el-form-item>
      <el-form-item :label="$t('contact.labelname')" prop="name">
        <el-input v-model="form.name" maxlength="32" :placeholder="$t('contact.holdername')" clearable></el-input>
      </el-form-item>
      <el-form-item :label="$t('contact.labelphone')" prop="phone">
        <el-input v-model="form.phone" maxlength="32" :placeholder="$t('contact.holderphone')" clearable></el-input>
      </el-form-item>
      <el-form-item :label="$t('contact.labeldemand')" prop="demand">
        <el-input v-model="form.demand" maxlength="256" :placeholder="$t('contact.holderdemand')" clearable></el-input>
      </el-form-item>
      <el-form-item :label="$t('contact.labeladdr')" prop="addr">
        <el-input v-model="form.addr" maxlength="128" :placeholder="$t('contact.holderaddr')" clearable></el-input>
      </el-form-item>
      <el-form-item :label="$t('contact.labelremark')" prop="remark">
        <el-input v-model="form.mes" maxlength="256" type="textarea" :rows="2" :placeholder="$t('contact.holderremark')" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="valid" :loading="loading">{{this.$t("submit")}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
export default {
  data() {
    const validator = (rule, value, callback) => {
      if (/^[\d-]{6,}$/.test(value)) {
        callback()
      } else {
        callback(new Error(this.$t('contact.phoneerr')))
      }
    }
    return {
      rules: {
        company: [ { required: true, message: this.$t("contact.msgcompany"), trigger: 'blur' } ],
        name: [ { required: true, message: this.$t("contact.msgname"), trigger: 'blur' } ],
        phone: [ { required: true, message: this.$t("contact.msgphone"), trigger: 'blur' }, { validator, trigger: 'blur'} ],
        demand: [ { required: true, message: this.$t("contact.msgdemand"), trigger: 'blur' } ],
        addr: [ { required: true, message: this.$t("contact.msgaddr"), trigger: 'blur' } ],
      },

      form: {
        company: '',
        name: '',
        phone: '',
        demand: '',
        addr: '',
        mes: ''
      },

      loading: false
    }
  },
  methods: {
    valid() {
      this.$refs.ruleForm.validate(valid => {
        console.log('valid:', valid)
        if (valid) {
          this.submit()
        }
      })
    },
    submit() {
      console.log(this.form)
      this.form.mes = this.form.mes || '--'
      this.form.email = 'luoduan@aimore.com'
      this.loading = true
      axios({
        url: 'https://dev.aimore.com/customer/insert',
        method: 'POST',
        data: qs.stringify(this.form)
      }).then(ret => {
        console.log('ret:', ret)
        this.$alert(this.$t("contact.submitted"))
        this.form.company = ''
        this.form.name = ''
        this.form.phone = ''
        this.form.demand = ''
        this.form.addr = ''
        this.form.mes = ''
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  height: 0;
  width: 100%;
  padding-top: 81.32%;
  background-image: url('../../assets/contact.png');
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;

  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    color: #FFF;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .logo {
    position: absolute;
    top: 18.1%;
    width: 32.26%;
  }

  .title {
    position: absolute;
    top: 44.57%;
    text-align: center;
    
    h2 {
      font-weight: 500;
    }
  }

  p {
    font-size: 12px;
    top: 72.13%;
    position: absolute;
    color: rgba(255,255,255,.7);
    padding: 4px 8px;
    border: 1px solid currentColor;
  }
}

.el-form {
  padding: 0 36px;
  margin-bottom: 36px;

  .form-title {
    margin: 28px 0 24px;
    text-align: center;
    h2 {
      font-weight: 500;
    }

    h2 + h2 {
      margin-top: 6px;
    }
  }

  .el-form-item {
    &::v-deep .el-form-item__label {
      line-height: 1;
      padding-bottom: 12px;
    }

    .el-input {
      border-radius: 2px;
      &::v-deep input {
        border-radius: 2px;
      }
    }
  }

  .el-button {
    width: 100%;
    border-radius: 2px;
  }
}
</style>
